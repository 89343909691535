<template>
  <div>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-else class="p-4">
      <form action="">
        <div class="vx-row mb-6">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{ $t('fields.code') }}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="'required_if:isBlocked,false'" name="code" class="w-full" v-model="supplier.code" placeholder="101012345" />
            <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
          </div>
        </div>
        <!-- <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{ $t('fields.username') }}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="'required'" class="w-full" name="username" v-model="supplier.username" placeholder="User name" />
            <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>
          </div>
        </div> -->
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{ $t('fields.name') }}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <div class="vx-col my-2 w-full">
              <vs-input v-validate="'required'" class="w-full" name="name_tm" :placeholder="$t('fields.name')" v-model="supplier.name" />
              <span class="text-danger text-sm" v-show="errors.has('name_tm')">{{ errors.first('name_tm') }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{ $t('fields.phone') }}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <div class="vx-col my-2 w-full">
              <vs-input v-validate="'required'" class="w-full" name="phone" placeholder="+993" v-model="supplier.phone" />
              <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">Em{{ $t('fields.email') }}ail:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="'required_if:isBlocked,false'" name="email" class="w-full" v-model="supplier.email" />
            <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{ $t('props.company_name') }}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="'required'" name="company_name" class="w-full" v-model="supplier.company_name" />
            <span class="text-danger text-sm" v-show="errors.has('company_name')">{{ errors.first('company_name') }}</span>
          </div>
        </div>
        <!-- <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">Password:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="'required|min:6'" name="password" type="password" class="w-full" v-model="supplier.password" />
            <span class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
          </div>
        </div> -->
        
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{ $t('isBlocked') }}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <li class="vx-col my-2 ml-2 mr-4 flex">
              <vs-switch name="isBulk" ref="isBlocked" v-model="supplier.isBlocked" />
              <label class="ml-3">{{ $t('block') }}?</label>
            </li>
          </div>
        </div>
        <vs-row>
          <vs-button type="filled" @click.prevent="submitForm" class="ml-auto">{{ $t('submit') }}</vs-button>
          <vs-button type="border" color="warning" @click.prevent="$router.back()" class="ml-4 mr-auto block">{{ $t('cancel') }}</vs-button>
        </vs-row>
      </form>
    </vx-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      supplier: {},
      isLoading: true
    }
  },
  async created () { await this.fetchData() },
  methods: {
    async fetchData () {
      await this.$http.get(`/suppliers/${this.$route.params.id}`).then(res => {
        this.supplier = res.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        this.notify('Error', err.message, 'icon-alert-circle', 'danger')
      })
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/suppliers/edit/${this.$route.params.id}`, {
            ...this.supplier
          }).then(response => {
            if (response.status === 200) {
              this.notify(this.$t('notify.success'), `${this.$t('supplier')} ${this.$t('notify.sUpdated')}`, 'icon-check-circle', 'primary')
              this.$router.back()
            }
          }).catch(err => { this.notify(this.$t('notify.error'), err.message, 'icon-alert-circle', 'danger') })
        } else { this.notify(this.$t('notify.error'), this.$t('notify.formInvalid'), 'icon-alert-circle', 'danger') }
      })
    },
    notify (title, text, icon, color) {
      this.$vs.notify({ title, text, iconPack: 'feather', icon, color })
    }
  }
}
</script>
